/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./tips.less";

const Tips = () => {
  return (
    <div className="tips">
      <h5>注释：</h5>
      <p>1、未经本网站和作者共同同意，其他任何机构或个人均不得以任何形式侵犯其作品著作权，包括但不限于：擅自复制、链接、非法使用或转载，或以任何方式建立作品镜像。</p>
      <p>2、本网站所分享的各类形式(包括但不仅限于文字、图片、视频)的内容仅供参考使用，不构成任何建议。对于访问者根据本网站提供的信息所做出的一切行为，本网站不承担任何形式的责任。</p>
      <p>3、本网站部分内容来自互联网，如无意中侵犯了某些媒体、公司、企业或个人等的知识产权，请来电或致函告之，本网站将在规定时间内给予删除等相关处理。</p>
      <p>4、对比栏中涉及数据均为本网站随机抽取数据计算所得，不具备权威性，仅供参考使用，不构成任何建议。</p>
    </div>
  );
};
export default Tips;
