/**
 ** Created By liuqi on 2019-08-06
 */
import React from 'react'

import Layout from '../components/layout'
import Tips from "../components/tips/tips"
import ContactUs from "../components/contactUs/contactUs"

import './teacherp.less'
import {Video} from '../utils/constant'

import icon_minshiweike from '../images/icon_minshiweike.png'
import icon_tiku from '../images/icon_tiku.png'
import icon_shuzipingtai from '../images/icon_shuzipingtai.png'
import icon_jiaoyanfuwu from '../images/icon_jiaoyanfuwu.png'
import pic_service1 from '../images/pic_service1.jpg'
import pic_service2 from '../images/pic_service2.jpg'
import pic_service3 from '../images/pic_service3.jpg'
import pic_videoEasy from '../images/pic_videoEasy.jpg'
import pic_videoShadow from '../images/pic_videoShadow.png'
import pic_videoTeacher from '../images/pic_videoTeacher.jpg'
import pic_videoStudent from '../images/pic_videoStudent.jpg'

class TeacherP extends React.Component{

  constructor(props) {
    super(props);
    this.state = {};
    this.videoEasy = React.createRef();
    this.videoTeacher = React.createRef();
    this.videoStudent = React.createRef();
    this.videoBox = [this.videoEasy, this.videoStudent, this.videoTeacher];
  }

  componentDidMount() {

  }

  imgScaleStart(e) {
    e.target.classList.remove('imgAnimationEnd');
    e.target.classList.add('imgAnimationStart');
  }

  imgScaleEnd(e) {
    e.target.classList.remove('imgAnimationStart');
    e.target.classList.add('imgAnimationEnd');
  }

  play(e) {
    if (!e.target) {
      return;
    }
    e.preventDefault();
    const video = e.target;
    if (video.nodeName.toLowerCase() === 'video') {
      this.dealVideoBox(video);
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  }

  dealVideoBox(video) {
    this.videoBox.forEach((item) => {
      const videoElem = item.current;
      if (video === videoElem) {
        return;
      }
      if (!videoElem.paused) {
        videoElem.pause();
      }
    });
  }

  render() {
    return (
      <Layout>
        <div className='teacherTop'></div>
        {/* 我们的比较优势 */}
        <section className='teacherSectionAdvantage'>
          <div className='teacherInnerBox'>
            <h3 className='commonTitleH3'>我们的优势</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <ul className='table'>
              <li className='table-row'>
                <div className='table-cell'></div>
                <div className='table-cell'>
                  <p>智课堂备课</p>
                </div>
                <div className='table-cell'>
                  <p>传统课堂</p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>备课优势</div>
                <div className='table-cell'>
                  <p>用时少</p>
                </div>
                <div className='table-cell'>
                  <p>用时长</p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>上课优势</div>
                <div className='table-cell'>
                  <p>
                    借助精品微课,实现分层教学；<br />
                    借助系统界面,了解学生学习情况;<br />
                    投屏展示:选取代表性强；
                  </p>
                </div>
                <div className='table-cell'>
                  <p>
                    同一难度层次教学；<br />
                    靠眼神揣测，把握程度不高；<br />
                    学生板书：代表性不强；
                  </p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>作业优势</div>
                <div className='table-cell'>
                  <p>
                    客观题系统自判；<br />
                    主观题老师抽查改判；<br />
                    批改时间节省50%；
                  </p>
                </div>
                <div className='table-cell'>
                  <p>
                    老师手动批改，工作量巨大；<br />
                    不能及时掌握作业的正答率；
                  </p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>课堂测试</div>
                <div className='table-cell'>
                  <p>系统自动推荐选题，组卷方便省力。</p>
                </div>
                <div className='table-cell'>
                  <p>翻阅海量资料，组卷判卷耗时费力。</p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>个性清错</div>
                <div className='table-cell'>
                  <p>老师一键操作，生成个性化清错课和个性化清错作业</p>
                </div>
                <div className='table-cell'>
                  <p>无或者难度极大。</p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>学情掌控</div>
                <div className='table-cell'>
                  <p>
                    记录每个场景教与学的数据，<br/>
                    老师精准教，学生针对性的学。
                  </p>
                </div>
                <div className='table-cell'>
                  <p>
                    教与学缺乏针对性，<br/>
                    统一指导。
                  </p>
                </div>
              </li>
              <li className='table-row'>
                <div className='table-cell'>结学伴</div>
                <div className='table-cell'>
                  <p>
                    学伴制（设定学习程度相当的同学作为学习目标和榜样，共同进步）。
                  </p>
                </div>
                <div className='table-cell'>
                  <p>
                    无
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* 老师如何教，学生如何学 */}
        <section className='teacherSectionHow'>
          <div className='teacherInnerBox'>
            <h3 className='commonTitleH3'>老师如何教，学生如何学</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <ul className='teacherHow'>
              <li>
                <img alt='' src={icon_minshiweike} />
                <h3 className='commonSubTitle'>
                  一套成体系的精品微课，<br/>
                  避免老师繁琐找资源
                </h3>
                <p className='commonSubTitleP'>
                  约20万节，课程覆盖9个学科，3级难度。
                </p>
              </li>
              <li>
                <img alt='' src={icon_tiku} />
                <h3 className='commonSubTitle'>
                  一套结构化题库，<br/>
                  例题同类题精准配套
                </h3>
                <p className='commonSubTitleP'>
                  例题同类题精准配套。
                </p>
              </li>
              <li>
                <img alt='' src={icon_shuzipingtai} />
                <h3 className='commonSubTitle'>
                  一套教学软硬件系统,<br/>
                  助力班内分层 精准教学
                </h3>
                <p className='commonSubTitleP'>
                  学生端、教师端软件、学生端psd，一个区域微云服务器系统。
                </p>
              </li>
              <li>
                <img alt='' src={icon_jiaoyanfuwu} />
                <h3 className='commonSubTitle'>
                  一套独有的封闭系统,<br/>
                  随地专注学习
                </h3>
                <p className='commonSubTitleP'>
                  保持日常沟通，随时电话咨询指导。
                </p>
              </li>
            </ul>
          </div>
        </section>
        {/* 界面简单易于操作 */}
        <section className='teacherSectionEasy'>
          <div className='teacherInnerBox'>
            <h3 className='commonTitleH3'>界面简单易于操作</h3>
            <p className='commonTitleP'>新老教师轻松上手</p>
            <div className='videoWrap'>
              <video className='video'
                     controls
                     controlsList='nodownload'
                     poster={pic_videoEasy} onClick={this.play.bind(this)}
                     loop ref={this.videoEasy}>
                <source
                  src={Video.JieGouKe}
                  type="video/mp4"/>
                你的浏览器不支持 html5 video播放
              </video>
            </div>
            <div className='videoShadow'>
              <img src={pic_videoShadow} alt=''  />
            </div>
          </div>
        </section>
        {/* 提供全程支持服务,定期分享使用案例 */}
        <section className='teacherSectionService'>
          <div className='teacherInnerBox'>
            <h3 className='commonTitleH3'>提供全程支持服务,定期分享使用案例</h3>
            <p className='commonSubTitleP'>简单智课堂拥有一套持续的教学教研支持服务，老师们在使用过程中遇到任何需求或问题，教学支持会快速反馈，协助解决。定期邀请优秀使用老师分享个人经验，共同进步。</p>
            <ul className='teacherService'>
              <li><img onMouseEnter={this.imgScaleStart.bind(this)}
                       onMouseLeave={this.imgScaleEnd.bind(this)}
                       alt='' src={pic_service1} /></li>
              <li><img onMouseEnter={this.imgScaleStart.bind(this)}
                       onMouseLeave={this.imgScaleEnd.bind(this)}
                       alt='' src={pic_service2} /></li>
              <li><img onMouseEnter={this.imgScaleStart.bind(this)}
                       onMouseLeave={this.imgScaleEnd.bind(this)}
                       alt='' src={pic_service3} /></li>
            </ul>
          </div>
        </section>
        {/* 教师反馈 */}
        <section className='teacherSectionFeedback'>
          <div className='teacherInnerBox'>
            <h3 className='commonTitleH3'>教师反馈</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <div className='videoWrap'>
              <video className='video'
                     controls
                     controlsList='nodownload'
                     poster={pic_videoTeacher} onClick={this.play.bind(this)}
                     loop ref={this.videoTeacher}>
                <source
                  src={Video.TeacherInterview}
                  type="video/mp4"/>
                你的浏览器不支持 html5 video播放
              </video>
            </div>
          </div>
        </section>

        <Tips/>
        <ContactUs/>
      </Layout>
    )
  }
}

export default TeacherP;
