/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import Message from "../message/message";
import "./contactUs.less";

import { checkoutPhone, checkoutNumber } from "../../utils/tools";
import { post } from "../../utils/apis";
import * as config from '../../../config/config';


class ContactUs extends React.Component {

  constructor(props) {
    super(props);
    this.state = { mobile: "", showMessage: "" };
  }

  changeMobile(e) {
    let value = e.target.value;
    if (checkoutNumber(value)) {
      this.setState({
        mobile: value
      });
    }
  }

  applyOperation() {
    if (checkoutPhone(this.state.mobile)) {
      // eslint-disable-next-line
      let a = this.requestFn();
    } else {
      this.setShowMessage("请填写正确的手机号!");
    }
  }

  setShowMessage(info) {
    this.setState({
      showMessage: info
    });
    setTimeout(() => {
      this.setState({
        showMessage: ""
      });
    }, 3000);
  }

  async requestFn() {
    let result = await post(config.url.hook + "/collaborate", { mobile: this.state.mobile });
    if (result.code === 0) {//邮件发送成功
      this.setState({
        mobile: ""
      });
    }
    this.setShowMessage(result.msg);
  }

  render() {
    return (
      <div className="contactUs">
        <div className='contactLeft'>
          <dl>
            <dt>
              <label/>
              <div>
                <span>联系我们</span>
                <label>CONTACT US</label>
              </div>
            </dt>
            <dd>
              <input type='text' placeholder="请输入手机号" value={this.state.mobile} onChange={this.changeMobile.bind(this)}
                     maxLength={11}/>
              <button onClick={this.applyOperation.bind(this)}>申请合作</button>
            </dd>
          </dl>
        </div>
        <div className='contactRight'>
          <dl className='rightSecond'>
            <dt>
              <label className='image2'/>
            </dt>
            <dd>
              简单智课堂服务号
            </dd>
          </dl>
        </div>
        {
          this.state.showMessage !== "" && <Message info={this.state.showMessage}/>
        }
      </div>
    );
  }
}

export default ContactUs;
